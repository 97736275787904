"use strict";

console.log('log from example-script.js');
/* 
    This is your main JS file (frontend). Don't rename it. You can split up your script into sepperate files inside the src/javascript/scripts/ folder.
    Vendors will be bundled and prepended before all of your own javascript. Stick your vendor js files inside the src/javascript/vendor/ folder.
    Make sure to declare all the vendors and custom scripts inside the projectConfig.js file.
*/
console.log('Main JS Loaded');
document.addEventListener('DOMContentLoaded', function () {
  $header = document.getElementsByClassName("c-header")[0];
  $slideshows = document.getElementsByClassName("c-slideshow__slides");
  window.addEventListener("scroll", function () {
    setScrollClasses();
  });

  // add everything scoll in this one
  function setScrollClasses() {
    checkHeader($header);
    function checkHeader($header) {
      var headerheight = $header.clientHeight;
      if (window.scrollY > headerheight) {
        $header.classList.add('scrolled');
      } else {
        $header.classList.remove('scrolled');
      }
    }
    ;
  }
  function initSlider($el) {
    // set variables, create elements
    var elH = $el.clientHeight;
    var $reel = $el.getElementsByClassName("c-slideshow__slidereel")[0];
    var $background = $el.getElementsByClassName("c-slideshow__background")[0];
    var $slides = $reel.getElementsByClassName("c-slideshow__slide");
    var $nav = $el.getElementsByTagName("nav")[0];
    for (var i = 0; i < $slides.length; i++) {
      var b = document.createElement("button");
      if (i == 0) {
        b.classList.add('active');
        $slides[i].classList.add('active');
      }
      $nav.appendChild(b);
      $slides[i].style.zIndex = $slides.length - i;
    }
    var $buttons = $nav.getElementsByTagName("button");
    var slideInterval = setInterval(autoPlay, 8000);
    function autoPlay() {
      if (parseInt($reel.dataset.slide) + 1 == $slides.length) {
        changeSlide($buttons[0]);
      } else {
        changeSlide($buttons[parseInt($reel.dataset.slide) + 1]);
      }
    }
    function indexInParent(node) {
      var children = node.parentNode.childNodes;
      var num = 0;
      for (var i = 0; i < children.length; i++) {
        if (children[i] == node) return num;
        if (children[i].nodeType == 1) num++;
      }
      return -1;
    }
    function removeActiveClasses(node) {
      var children = node.parentNode.childNodes;
      for (var i = 0; i < children.length; i++) {
        if (children[i].nodeType == 1) {
          children[i].classList.remove('active');
        }
      }
    }
    function changeSlide(target) {
      var dex = indexInParent(target);
      if ($reel.dataset.slide == dex) {} else {
        if (dex > $reel.dataset.slide) {
          $background.innerHTML = $slides[dex].innerHTML;
          setTimeout(function () {
            removeActiveClasses($slides[dex]);
            $slides[dex].classList.add('active');
          }, 1000);
        } else {
          if ($reel.dataset.slide - dex < 2) {
            $background.innerHTML = $slides[dex + 1].innerHTML;
          }
          removeActiveClasses($slides[dex]);
          $slides[dex].classList.add('active');
        }
        removeActiveClasses(target);
        $reel.dataset.slide = dex;
        $reel.style.top = -(elH * dex) + 'px';
        target.classList.add('active');
      }
    }
    for (i = 0; i < $buttons.length; ++i) {
      var $button = $buttons[i];
      $button.addEventListener("click", function (e) {
        clearInterval(slideInterval);
        var $target = event.target;
        changeSlide($target);
        slideInterval = setInterval(autoPlay, 5000);
      });
    }
  }
  ;
  function initNavigationListeners() {
    // first add raf shim
    window.requestAnimFrame = function () {
      return window.requestAnimationFrame || window.webkitRequestAnimationFrame || window.mozRequestAnimationFrame || function (callback) {
        window.setTimeout(callback, 1000 / 60);
      };
    }();
    var mainmenu = document.getElementById('menu-main');
    var menulinks = mainmenu.querySelectorAll('a');
    var mobilemenu = document.getElementsByClassName('c-header__mobilemenu')[0];
    var mobilemenulinks = mobilemenu.querySelectorAll('a');
    var ctas = document.getElementsByClassName('e-section--ctas')[0];
    var ctalinks = ctas.querySelectorAll('a');
    var $scrollDown = document.getElementsByClassName("scroll-down")[0];
    var menuToggler = document.getElementsByClassName("menu-toggler")[0];
    menuToggler.onclick = function (e) {
      if (document.body.classList.contains('menu-open')) {
        document.body.classList.remove('menu-open');
      } else {
        document.body.classList.add('menu-open');
      }
    };
    $scrollDown.onclick = function (e) {
      scrollToY(window.innerHeight - 60, 2000, 'easeInOutQuint');
    };
    var forEach = function forEach(array, callback, scope) {
      for (var i = 0; i < array.length; i++) {
        callback.call(scope, i, array[i]);
      }
    };
    forEach(menulinks, function (index, item) {
      var str = item.href;
      var re = /#(.*)/;
      var found = str.match(re);
      item.onclick = function (e) {
        e.preventDefault();
        scrollTo(found[1]);
      };
    });
    forEach(mobilemenulinks, function (index, item) {
      var str = item.href;
      var re = /#(.*)/;
      var found = str.match(re);
      item.onclick = function (e) {
        e.preventDefault();
        scrollTo(found[1]);
      };
    });
    forEach(ctalinks, function (index, item) {
      var str = item.href;
      var re = /#(.*)/;
      var found = str.match(re);
      item.onclick = function (e) {
        e.preventDefault();
        scrollTo(found[1]);
      };
    });
    function scrollTo(id) {
      $el = document.getElementById(id);
      var Yoffset = window.pageYOffset;
      var diff = $el.offsetTop - Yoffset;
      var start;
      document.body.classList.remove('menu-open');

      // scroll it!
      scrollToY(Yoffset + diff - 130, 2000, 'easeInOutQuint');
    }

    // main function
    function scrollToY(scrollTargetY, speed, easing) {
      var scrollY = window.pageYOffset,
        scrollTargetY = scrollTargetY || 0,
        speed = speed || 2000,
        easing = easing || 'easeOutSine',
        currentTime = 0;

      // min time .1, max time .8 seconds
      var time = Math.max(.1, Math.min(Math.abs(scrollY - scrollTargetY) / speed, .8));

      // easing equations from https://github.com/danro/easing-js/blob/master/easing.js
      var PI_D2 = Math.PI / 2,
        easingEquations = {
          easeOutSine: function easeOutSine(pos) {
            return Math.sin(pos * (Math.PI / 2));
          },
          easeInOutSine: function easeInOutSine(pos) {
            return -0.5 * (Math.cos(Math.PI * pos) - 1);
          },
          easeInOutQuint: function easeInOutQuint(pos) {
            if ((pos /= 0.5) < 1) {
              return 0.5 * Math.pow(pos, 5);
            }
            return 0.5 * (Math.pow(pos - 2, 5) + 2);
          }
        };

      // add animation loop
      function tick() {
        currentTime += 1 / 60;
        var p = currentTime / time;
        var t = easingEquations[easing](p);
        if (p < 1) {
          requestAnimFrame(tick);
          window.scrollTo(0, scrollY + (scrollTargetY - scrollY) * t);
        } else {
          window.scrollTo(0, scrollTargetY);
        }
      }

      // call it once to get started
      tick();
    }
  }
  initNavigationListeners();
  let i;
  for (i = 0; i < $slideshows.length; i++) {
    // console.log($slideshows[i]);
    initSlider($slideshows[i]);
  }
});